<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.link" :to="item.link">{{ item.text }}</v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'ButcherHome',
  components: {
  },
  data () {
    return {
      tab: null,
      items: [
        {
          text: i18n.t('butcher.tab.overview'),
          link: '/butcher'
        },
        {
          text: i18n.t('butcher.tab.slaughter'),
          link: '/butcher/slaughter'
        },
        {
          text: i18n.t('butcher.tab.billOfLading'),
          link: '/butcher/bill-of-lading'
        },
        {
          text: i18n.t('butcher.tab.carcassReport'),
          link: '/butcher/carcass-report'
        },
        {
          text: i18n.t('butcher.tab.inventory'),
          link: '/butcher/inventory'
        },
        {
          text: i18n.t('butcher.tab.pieces'),
          link: '/butcher/pieces'
        }
      ]
    }
  }
}
</script>
